<template>
  <div :class="$style.promos">
    <div :class="$style.wrapper">
      <el-button size="small" type="primary" @click="submitForm('form')">
        Создать
      </el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      :class="$style.promoGiftCodeCreateForm"
      ref="form"
      label-position="right"
      label-width="240px"
    >
      <el-form-item label="Имя клиента" prop="clientName">
        <el-input v-model="form.clientName"></el-input>
      </el-form-item>
      <el-form-item label="Телефон клиента" prop="clientPhone">
        <el-input
          v-model="form.clientPhone"
          placeholder="+XXXXXXXXX, 9-15 символов"
        ></el-input>
      </el-form-item>
      <el-form-item label="Тип" prop="type">
        <el-select
          :class="$style.select"
          v-model="form.type"
          placeholder="Выберите тип промокода"
        >
          <el-option
            v-for="(item, index) in $options.PROMOCODE_TYPE_DISPLAY"
            :key="item + index"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Значение" prop="value" :rules="rules.value">
        <el-input-number
          v-model.number="form.value"
          controls-position="right"
          :min="0"
        >
        </el-input-number>
      </el-form-item>
      <el-form-item
        label="Мин. Сумма заказа для использования"
        prop="minPriceToApply"
      >
        <el-input-number
          v-model.number="form.minPriceToApply"
          controls-position="right"
          :min="0"
        >
        </el-input-number>
      </el-form-item>
      <el-form-item label="Код" prop="code">
        <el-input v-model="form.code" readonly="true"></el-input>
      </el-form-item>
      <el-form-item label="Применим к товарам со скидкой" prop="isDiscountable">
        <el-checkbox v-model="form.isDiscountable"></el-checkbox>
      </el-form-item>
      <el-form-item label="Ассортименты" prop="assortmentIds">
        <el-select
          v-model="form.assortmentIds"
          v-el-select-loadmore="loadMoreAssortments"
          :remote-method="searchAssortments"
          filterable
          clearable
          remote
          multiple
          placeholder="Выберите ассортименты"
          :class="$style.select"
        >
          <el-option
            v-for="item in assortments"
            :key="item?.id"
            :label="item?.name"
            :value="item?.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'
import { debounce } from '@/helpers/debounce.js'
import {
  PROMOCODE_TYPE_DISPLAY,
  PROMOCODE_TYPE,
} from '@/constants/promocodeTypes'
import { ADDWINE_ADMIN_ROUTES, getRoute } from '@/constants/routing'

export default {
  PROMOCODE_TYPE_DISPLAY,
  PROMOCODE_TYPE,
  mixins: [notifications],
  async created() {
    await this.getAssortments()
    this.assortmentsSearch = debounce(this.getAssortments, 500)
  },
  data() {
    return {
      assortments: [],
      assortmentsOptions: {
        limit: 10,
        page: 1,
        total: 0,
        search: '',
      },
      form: {
        code: '',
        clientName: '',
        clientPhone: '',
        value: 0,
        minPriceToApply: 0,
        isDiscountable: false,
        assortmentIds: [],
        type: null,
      },
      rules: {
        clientName: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        clientPhone: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.phone,
            message: 'Неправильный номер',
            trigger: 'change',
          },
        ],
        value: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            trigger: 'blur',
            message: 'Значение промокода в процентах не может быть больше 100',
            validator: this.validatePromoValue,
          },
        ],
        type: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    resetForm() {
      this.form = {
        code: '',
        clientName: '',
        clientPhone: '',
        value: 0,
        minPriceToApply: 0,
        isDiscountable: false,
        assortmentIds: [],
        type: null,
      }
    },
    async getAssortments() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ProductsCore.AssortmentsActions.getList(
          this.assortmentsOptions,
        )

      loading.close()

      if (error) return

      this.assortments = [...this.assortments, ...value.data]
      this.assortmentsOptions.total = value.meta.count
    },
    loadMoreAssortments() {
      const { page, limit, total } = this.assortmentsOptions
      if (limit * page < total) {
        this.assortmentsOptions.page++
        this.getAssortments()
      }
    },
    async searchAssortments(search) {
      this.assortmentsOptions.page = 1
      this.assortmentsOptions.search = search
      this.assortments = []
      await this.assortmentsSearch()
    },
    validatePromoValue(rule, value, callback) {
      if (this.form.type == PROMOCODE_TYPE.PERCENTAGE && value > 100)
        return callback(false)
      return callback()
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const { error, value } =
            await delivery.PaymentServiceCore.GiftPromosActions.create(
              this.form,
            )
          loading.close()
          if (error || !value) {
            return this.showNotification('Ошибка создания промокода', 'error')
          }

          this.showNotification('Промокод успешно создан', 'success')

          this.$router.push(
            getRoute({
              route: ADDWINE_ADMIN_ROUTES.PROMOTIONS.PROMOS.GIFT_PROMOS.VIEW,
              params: { id: value?.id },
            }),
          )
          this.resetForm()
        }
      })
    },
    getRoute({ route, params, site }) {
      return getRoute({ route, params, site })
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.promos {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .tag + .tag {
    margin-left: 0.625rem;
  }
  .buttonNewTag {
    margin-left: 0.625rem;
    height: 2rem;
    line-height: 1.875rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .inputNewTag {
    width: 16rem;
    margin-left: 0.625rem;
    vertical-align: bottom;
  }
  label {
    margin: 0;
  }
  .filterItem > label {
    width: auto !important;
  }
  .promoGiftCodeCreateForm {
    margin-top: 0.625rem;
  }
}
</style>
